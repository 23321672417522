<template>
<v-app>

  <v-dialog  v-model="alert_msg"  width="500">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Mensagem
      </v-card-title>

      <v-card-text>
          {{msg_dialog}}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="alert_msg = false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
</v-dialog>

<v-dialog  v-model="alert_infor"  width="500">
  <v-card>
    <v-card-title class="text-h5 grey lighten-2">
      Informações do documento
    </v-card-title>

    <v-card-text>

        <span style="font-weight: bold;">Usuário de Criação: </span>{{this.infor4}}  <br/>
        <span style="font-weight: bold;">Data de Criação: </span>{{this.infor3}}  <br/>
        <span style="font-weight: bold;">Titulo: </span> {{this.infor5}}  <br/>
        <span style="font-weight: bold;">Descrição: </span> {{this.infor1}}  <br/>
        <span style="font-weight: bold;">Nome do Arquivo: </span>{{this.infor2}}  <br/>


        <br/>
        <v-data-table
            :headers="headers_historico"
            :items="lista_historico"
            :items-per-page="5" :footer-props="{
              'items-per-page-options': [5],
              'items-per-page-text':'Registro por página'
            }"
            class="elevation-0">

                      <template v-slot:item="row">
                        <tr>
                          <td style="width: 12%;font-size:14px;">{{row.item.user}} </td>
                          <td style="width: 12%;font-size:14px;">{{row.item.data_insert}} </td>
                          <td style="width: 12%;font-size:14px;">{{row.item.acao}} </td>

                        </tr>
                    </template>


          </v-data-table>

    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="alert_infor = false"
      >
        OK
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

<v-dialog  v-model="dlg_delete"  width="500">
  <v-card>
    <v-card-title class="text-h5 red lighten-2">
      Mensagem
    </v-card-title>

    <v-card-text>
        Deseja deletar o arquivo?
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
        color="secundary" 
        text
        @click="dlg_delete = false"
      >
        Cancelar
      </v-btn>

      <v-btn
        color="red"
        @click="setConfirmDelete"
      >
        OK
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>



  <v-container
    id="dashboard"
    fluid
    tag="section">
    
   

<base-material-card
      icon="mdi-file"
      title="Documentos"
      class="px-5 py-3">

      								

  <v-card>
    <v-card-title>
      <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Pesquisa"
      single-line
      hide-details
    ></v-text-field>
  </v-card-title>

        <v-data-table :search="search"  :loading="loading_table" loading-text="Carregando... Aguarde"
        
          :headers="headers_doc"
          :items="lista_documentos" 
          :items-per-page="30"   :footer-props="{
            'items-per-page-options': [30, 40, 50],
            'items-per-page-text':'Registro por página'
          }"
          class="elevation-0">

                    <template v-slot:item="row">
                      <tr>
                        <td style="width: 8%;font-size:14px;">{{row.item.id}}</td>
                        <td style="width: 35%;font-size:14px;">{{row.item.titulo}}</td>
                        <td style="width: 10%;font-size:14px;">{{row.item.data_insert}} </td>
                        <td style="width: 8%;font-size:18px;">

                                <v-chip 
                                      :color="getStatus(row.item.empresa)"
                                      text-color="white">
                                          {{row.item.empresa}}                    
                                          
                                </v-chip>
                        
                        </td>

                        <td style="width: 10%;font-size:14px;">
                          
                          <img style="width:32px" src="./../../assets/icon_pdf.png" v-if="row.item.tipo == 'PDF'" />
                          <img style="width:32px" src="./../../assets/icon_png.png" v-if="row.item.tipo == 'PNG'" />
                          <img style="width:32px" src="./../../assets/icon_xlsx.png" v-if="row.item.tipo == 'XLSX'" />
                          <img style="width:32px" src="./../../assets/icon_xlsx.png" v-if="row.item.tipo == 'XLS'" />
                          <img style="width:32px" src="./../../assets/icon_docx.png" v-if="row.item.tipo == 'DOC'" />
                          <img style="width:32px" src="./../../assets/icon_docx.png" v-if="row.item.tipo == 'DOCX'" />


                        </td>


                        <td style="width: 5%;font-size:14px;">
                              <v-btn class="mx-2"  outlined dark  x-small v-bind="attrs" v-on="on"  @click="getInfor(row.item)"
                              color="teal">
                                + Detalhes
                              </v-btn> 
                        </td>


                  
                        

                        <td style="width: 25%;font-size:14px;">
                          <v-progress-circular v-if="loading_download == true && row.item.id == n_download"
                        indeterminate
                        color="primary"
                          ></v-progress-circular>

                          <v-tooltip bottom v-if="loading_download == false || row.item.id != n_download">    
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="mx-2"  small fab  dark   v-bind="attrs" v-on="on" @click="getDownload(row.item)" 
                              color="cyan">
                                <v-icon dark>
                                  mdi-download
                                </v-icon>
                              </v-btn> 
                            </template>
                            <span>Download</span>
                          </v-tooltip>   
                       

                          <v-tooltip bottom v-if="row.item.userid == userid">    
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="mx-2"   fab  dark   small v-bind="attrs" v-on="on" @click="setDelete(row.item)" 
                              color="red">
                                <v-icon dark>
                                  mdi-delete
                                </v-icon>
                              </v-btn> 
                            </template>
                            <span>Delete</span>
                          </v-tooltip>   

                       

                        </td>

                        

                      </tr>
                  </template>


        </v-data-table>
      </v-card>
 </base-material-card>

  

  </v-container>

</v-app> 
</template>

<script>
  import Documento from '../../services/documento'
  import VueApexCharts from 'vue-apexcharts'
  import documento from '../../services/documento';
  import axios from 'axios'
  import authHeader from '../../services/auth-header';
  import config  from '../../services/config'



  export default {
  components: {
    apexcharts: VueApexCharts,
  },
    mounted() {
          this.loading_table = true;

          Documento.listadoc().then(response => {
                if(response.status = 200){
                  this.lista_documentos = response.data;
                  this.loading_table = false;
                }
              }).catch(e => {
                this.loading_table = false;

              }).finally(() => {
            });

            this.user = JSON.parse(sessionStorage.getItem('user')).nome;
            this.userid = JSON.parse(sessionStorage.getItem('user')).id;


    },

    name: 'DashboardDashboard',
 
    data () {
      return {
     
        headers_historico: [
          { text: 'Usuário', value: 'user' },
          { text: 'Data', value: 'data_insert' },
          { text: 'Ação', value: 'acao' },

        ],

        headers_doc: [
          { text: 'Nº', value: '' },
          { text: 'Titulo', value: 'titulo' },
          { text: 'Data', value: 'data_insert' },
          { text: 'Empresa', value: 'empresa' },
          { text: 'Tipo', value: '' },

        ],
        lista_documentos: [],
        alert_msg: false,
        loading_table:false,
        loading_download:false,
        n_download: '',
        msg_dialog: '',
        alert_infor: false,
        lista_historico: [],
        infor1: '',
        infor2: '',
        infor3: '',
        infor4: '',
        infor5: '',

        user: '',
        userid: '',
        dlg_delete: false,
        value: {},
        search: '',
        
    }
  },

    methods: {

      getInfor(documento){
          this.infor1 = documento.descricao;
          this.infor2 = documento.file_name;
          this.infor3 = documento.data_insert;
          this.infor4 = documento.user;
          this.infor5 = documento.titulo;

          this.alert_infor = true;

          Documento.listahistoricoIdDoc(documento.id).then(response => {
                if(response.status = 200){
                  this.lista_historico = response.data;
                }
              }).catch(e => {
              }).finally(() => {
            });



      },

      setDelete(valor){
          this.value = valor;
          this.dlg_delete = true;
      },

      setConfirmDelete(){
          Documento.deleta(this.value.id).then(response => {
                if(response.status = 200){
                  let userIndex = this.lista_documentos.indexOf(this.value)
                  this.lista_documentos.splice(userIndex, 1);
                  this.dlg_delete = false;
                  this.msg_dialog = response.data.mensagem;
                  this.alert_msg = true

                }
              }).catch(e => {
                if(e.response.status === 401 || e.response.status === 403){
                      this.dlg_delete = false
                      this.alert_msg = true;
                      this.msg_dialog = "Usuário não tem permissão para deletar";

              }else{
                this.alert_msg = true
                this.msg_dialog = 'Problemas de Comunicação com o servidor, tente novamente mais tarde ou entre em contato com o Suporte'
              }
              }).finally(() => {
                this.value = {};
            });
      },


      getDownload(documento){
        this.loading_download = true;
        this.n_download = documento.id;
        axios({          
          url: config.url + 'documento.download?id=' + documento.id,
          method: 'GET',
          headers: authHeader(),
          responseType: 'blob',
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.size > 1) {
                  const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                  const fileLink = document.createElement('a')
                  fileLink.href = fileURL
                  fileLink.setAttribute('download',  documento.file_name)
                  document.body.appendChild(fileLink)
                  fileLink.click()
              }else{
                  this.alert_msg = true
                  this.msg_dialog = 'Não existe documento anexo ao contrato'
          
              }
          } else {
            this.alert_msg = true
            this.msg_dialog = 'Problemas de Comunicação com o servidor, tente novamente mais tarde ou entre em contato com o Suporte'
          }
          this.loading_download = false;
          this.n_download = '';

        }).catch((e) => {
              if(e.response.status === 401 || e.response.status === 403){
                      this.loading_table = false
                      this.alert_msg = true;
                      this.btn_load = false;
                      this.msg_dialog = "Usuário não tem permissão para upload";
              }else{
                this.alert_msg = true
                this.msg_dialog = 'Problemas de Comunicação com o servidor, tente novamente mais tarde ou entre em contato com o Suporte'
              }
              this.loading_download = false;
              this.n_download = '';


        })

      },



      getStatus (status) {

        switch (status) {
           case 'Grupo Parque das Flores': return 'blue-grey darken-2'
           case 'Memorial Parque Maceió' : return 'green lighten-1'
           case 'Cemitério Parque das Flores' : return 'orange lighten-1'
           case 'Previda - Previparq' : return 'blue lighten-1'
           case 'Cemitério Previda - VERSAN' : return 'cyan lighten-1'
           case 'GPF Corretora de Seguros' : return 'purple lighten-1'
           case 'A Flor do Café - Melro & Sampaio' : return 'red lighten-1'
           case 'Cemitério Parque do Agreste - AM' : return 'indigo lighten-1'
           case 'Cemitério Reserva da Paz' : return ' pink darken-4 lighten-1'
          default: 'success'
        }
      },

      
    },
  }
</script>

<style scoped>
  .v-progress-circular {
    margin: 1rem;
  }
  </style>
